import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import Breadcrumb from "../navigation/breadcrumb";
import EditApplicationClaims from "./editApplicationClaims";
import EditApplicationDetails from "./editApplicationDetails";
import * as applicationActions from "../../redux/actions/applicationsActions";
import DisplayError from "../errors/displayError";
import CredentialGenerator from "../credentialGenerator/CredentialGenerator";

class EditApplication extends React.Component {
  regex = new RegExp("^[\\w\\s-]{1,100}$"); //the underlying API only supports this regex "[\w\s+=,.@-]+", we want less special chars in names so only words, whitespace and hyphens are allowed

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      changed: false,
      claims: [],
      errors: {},
      brands: [
        {
          carrierName: "NZCouriers",
        },
        {
          carrierName: "PostHaste",
        },
        {
          carrierName: "DXMail",
        },
        {
          carrierName: "KiwiExpress",
        },
        {
          carrierName: "CastleParcels",
        },
        {
          carrierName: "NowCouriers",
        },
      ],
      credentialsPopupOpen: false,
      currentOrganisation: props.application.organisation,
      currentApplication: props.application.applicationName,
      isActive: props.application.isActive,
    };
  }

  componentDidMount() {
    if (Object.keys(this.props.application).length === 0) {
      //fetch application and then get the claims
      this.props.actions.getApplicationsList().then(() => {
        this.getClaims().then(() => this.formReady());
      });
    } else {
      //application data already exists, just get the claims
      this.getClaims().then(() => {
        this.formReady();
      });
    }
  }

  getApplicationsList() {
    return this.props.actions.getApplicationsList();
  }

  getClaims() {
    return this.props.actions.getApplicationClaims(
      this.props.match.params.appclientid
    );
  }

  formReady() {
    this.setState({
      ...this.state,
      currentOrganisation: this.props.application.organisation,
      currentApplication: this.props.application.applicationName,
      isActive: this.props.application.isActive,
      ready: true 
    });
  }

  showCredentials(open) {
    if (open) {
      this.props.actions.getApplicationCredentials(this.props.application.appClientId)
      .then(() => {
        this.setState({ ...this.state, credentialsPopupOpen: open });
      })
      .catch(() => {
        console.log("Error retrieving credentials");
      });
    } else {
      this.setState({ ...this.state, credentialsPopupOpen: open });
    }
  }

  formChanged(changedState = true) {
    return (
      this.state.changed != changedState &&
      this.setState({ ...this.state, changed: changedState })
    ); //only set changed to true once to avoid unnessecary re-renders
  }

  saveClick = (event) => {
    event.preventDefault();

    let errors = {
      organisation: '',
      applicationName: ''
    };

    if (!this.state.currentOrganisation) {
      errors.organisation = "Organisation is required.";
    } else if (!this.regex.test(this.state.currentOrganisation)) {
      errors.organisation = "Organisation must be 100 characters or less, contain only numbers, letters, spaces and hyphens.";
    }

    if (!this.state.currentApplication) {
      errors.applicationName = "Application Name is required.";
    } else if (!this.regex.test(this.state.currentApplication)) {
      errors.applicationName = "Application Name must be 100 characters or less, contain only numbers, letters, spaces and hyphens.";
    }

    errors.claimErrors = this.validateClaims(this.state.claims);

    errors = {...this.state.errors, ...errors};

    this.setState({...this.state, errors});

    if (errors.organisation === '' && errors.applicationName === '' && errors.claimErrors.length === 0) {
     this.props.actions
      .updateApplication(
        this.props.application.appClientId,
        this.state.currentOrganisation,
        this.state.currentApplication,
        this.state.isActive,
        this.state.claims
      )
      .then(() => {
        this.formChanged(false);
        toast.success("Application updated");
      })
      .catch((e) => {
        toast.error(e.message);
      });
    }
  };

  cancelClick = (event) => {
    event.preventDefault();
    this.props.history.push("/");
  };

  onClaimUpdated = (updatedClaim) => {
    let claims = this.state.claims.map((claim) =>
      claim.claimId === updatedClaim.claimId ? updatedClaim : claim
    );

    this.updateClaimsState(claims);
  };

  onClaimAdded = (newClaim) => {
    let claims = [...this.state.claims, newClaim];

    this.updateClaimsState(claims);
  };

  onClaimRemoved = (removedClaimId) => {
    let claims = this.state.claims.filter(
      (claim) => claim.claimId !== removedClaimId
    );

    this.updateClaimsState(claims);
  };

  onDetailsChange = (changedAttr) => {
    this.setState(
      {...this.state, [changedAttr.key]: changedAttr.value},
      () => {
        this.formChanged(true);
      }
    );
  };

  isValidCarrierName = (carrierName) => {
    return (
      this.state.brands.filter((brand) => brand.carrierName === carrierName)
        .length > 0
    );
  };

  isValidCustomerId = (customerId) => {
    const regex = "^[A-Z0-9]{1,8}$";
    return customerId.match(regex);
  };

  validateClaims(claims) {
    let errors = [];

    claims.forEach(({ claimId, carrierName, customerId }) => {
      if (!this.isValidCarrierName(carrierName)) {
        errors.push({
          key: claimId + "-carrierName",
          value: "Select a valid brand",
        });
      }

      if (!this.isValidCustomerId(customerId)) {
        errors.push({
          key: claimId + "-customerId",
          value: "Numbers and capitals only, 8 characters or less",
        });
      }
    });

    return errors;
  }

  updateClaimsState = (claims) => {
    this.setState(
      {...this.state, claims },
      () => {
        this.formChanged(true);
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    //Update the component state to reflect claims from redux store when the store updates
    //claims are requried to be added to state because this component must keep track of
    //all claims for the app becuase the EditApplicationClaims component uses a datagrid
    //internally and that only dispatches changes without the original claim data
    if (
      JSON.stringify(prevProps.claims) === JSON.stringify(this.props.claims) &&
      prevState.claims.length <= 1 &&
      this.state.claims.length === 0 &&
      this.state.ready !== false
    ) {
      return;
    } else if (
      JSON.stringify(prevProps.claims) !== JSON.stringify(this.props.claims) ||
      (this.state.claims.length == 0 && this.props.claims && this.props.claims.length > 0)
    ) {
      this.setState({ ...this.state, claims: JSON.parse(JSON.stringify(this.props.claims)) });
    }
  }

  render() {
    let applicationExists = this.props.application ? true : false;

    return (
      <>
        {applicationExists ? (
          <Container className="mt-3">
            <Row>
              <Col>
                <Breadcrumb
                  links={[{ path: "/", name: "Applications" }]}
                  name="Edit Application"
                />
                <h2>Edit Application</h2>
                <EditApplicationDetails
                  organisation={this.state.currentOrganisation}
                  applicationName={this.state.currentApplication}
                  appClientId={this.props.application.appClientId}
                  onChange={this.onDetailsChange}
                  isActive={this.state.isActive}
                  errors={this.state.errors}
                />
                <Button variant="primary" data-test-id='showCredentialsButton' onClick={() => this.showCredentials(true)}>
                  Show credentials
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Accounts</h3>
                <EditApplicationClaims
                  claims={JSON.parse(JSON.stringify(this.state.claims))} //a deep copy of claims is requried here as the datagrid directly modify the claims property which would mutate the parents state which gets passed in
                  brands={this.state.brands}
                  onClaimUpdated={this.onClaimUpdated}
                  onClaimAdded={this.onClaimAdded}
                  onClaimRemoved={this.onClaimRemoved}
                  errors={this.state.errors.claimErrors || []}
                  key={this.props.appClientId}
                  loading={this.props.loading}
                />
              </Col>
            </Row>
            <Row>
              <Col className="align-contents-right pt-4">
                <Button variant="secondary" onClick={this.cancelClick}>
                  {this.state.changed ? "Cancel" : "Close"}
                </Button>{" "}
                <Button
                  data-test-id="saveButton"
                  variant="primary"
                  onClick={this.saveClick}
                  disabled={this.state.ready ? false : true}
                >
                  Save
                </Button>
              </Col>
            </Row>
            {this.state.credentialsPopupOpen && (
              <CredentialGenerator
                organisation=''
                applicationName=''
                showGenerateButton={false}
                showSecrets={true}
                showAlertMessage={false}
                secrets={[
                  { name: "Client ID", value: this.props.application.appClientId },
                  { name: "Client Secret", value: this.props.appCredentials }
                ]}
                onCloseOneTimeSecrets={() => this.showCredentials(false)}
              />
            )}
          </Container>
        ) : (
          <Container className="mt-3">
            <Row>
              <Col>
                <DisplayError errorMessage="Application does not exist" />
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}

EditApplication.propTypes = {
  actions: PropTypes.object.isRequired, //ensures that dispatch is passed in (it gets passed in automatically when connect does not have mapDispatchToProps parameter)
};

function getApplicationFromState(applicationsList, selectedAppClientId) {
  let result = {};

  if (applicationsList.length > 0 && selectedAppClientId.length > 0) {
    result = applicationsList.filter(
      (app) => app.appClientId === selectedAppClientId
    )[0];
  }

  return result;
}

function mapStateToProps(state, ownProps) {
  let selectedAppClientId = ownProps.match.params.appclientid;
  let selectedApplication = getApplicationFromState(
    state.applications.applicationsList,
    selectedAppClientId
  );

  return {
    application: selectedApplication,
    claims: state.applications.applicationClaims,
    appClientId: selectedAppClientId,
    loading: state.apiCallsInProgress > 0,
    appCredentials: state.applications.appCredentials,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(applicationActions, dispatch), //automatically maps all actions within dataActions.js instead of doing this for every action; getData: data => dispatch(actions.getData(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditApplication);
