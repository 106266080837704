const initialState = {
  applications: {
    applicationsList: [],
    applicationClaims: [],
    temporaryCredentials: {
      organisation: "",
      applicationName: "",
      appClientId: "",
      appClientSecret: "",
    },
    appCredentials: ""
  },
  users: [],
  despatchitkeys: {
    applications: [],
    temporaryCredentials: {
      appId: "",
      appKey: "",
      despatchItKey: "",
      name: "",
      organisation: ""
    },
    userPlans: []
  },
  error: {
    errorMessage: "",
  },
  apiCallsInProgress: 0
};

export default initialState;
