import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import FreDataTable from "../dataTable/FreDataTable";
import { createDateTimeTemplateDespatchITKeys } from "../../utils/datatableTemplate";

const ListDespatchITKeysApplications = ({ apps, showInactiveApps = false }) => {
  return (
    <div className="mt-3 ml-5 mr-5">
      <Row>
        <Col>
          <h2>DespatchITKeys Applications</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 10, span: 2 }} xl={{ offset: 11, span: 1 }}>
          <Link data-test-id="add-despatchitkeys-app-button" to="/despatchitkeys/addApplication" className="btn btn-primary">
            Add Application
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <FreDataTable data={apps}
            actionButtonBaseUrl="/despatchitkeys/"
            routeIdField="appId"
            columns={[
              {
                fieldName: "organisation",
                headerName: "Organisation"
              },
              {
                fieldName: "email",
                headerName: "Email"
              },
              {
                fieldName: "name",
                headerName: "Name"
              },
              {
                fieldName: "userPlan",
                headerName: "User Plan"
              },
              {
                fieldName: "isActive",
                headerName: "Status",
                disableSearch: true                
              },
              {
                fieldName: "dateCreated",
                headerName: "Date Created",
                body: createDateTimeTemplateDespatchITKeys
              },
              {
                fieldName: "appId",
                headerName: "App ID"
              }
            ]}
            inactiveToggle={{ canToggle: true, initialValue: showInactiveApps, fieldName: 'isActive', toggleText: 'Show Inactive Apps' }}
          />
        </Col>
      </Row>
    </div>
  );
};

ListDespatchITKeysApplications.propTypes = {
  apps: PropTypes.array.isRequired,
  showInactiveApps: PropTypes.bool
};

export default ListDespatchITKeysApplications;
