import React from "react";
import PropTypes from "prop-types";

const TextInput = ({ name, label, onChange, placeholder, value, error, readOnly, 'data-test-id': dataTestId }) => {
  let inputClass = "form-control";
  if (error.length > 0) {
    inputClass += " validation-error";
  }

  return (
    <div className="fieldBox">
      {label && <label htmlFor={name}>{label}</label>}
      <div className="field">
        <input
          data-test-id={dataTestId || ''}
          type="text"
          name={name}
          className={inputClass}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
        {error && <span className="validation-error-message">{error}</span>}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  'data-test-id': PropTypes.string
};

TextInput.defaultProps = {
  error: ""
}
export default TextInput;
