import React from "react";
import moment from "moment";

const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

export const createDateTimeTemplate = (row) => {
  let date = moment.utc().format(row.createdDateTime);
  let utcDateTime = moment.utc(date).toDate();
  return <span>{moment(utcDateTime).local().format(dateTimeFormat)}</span>;
};

export const createDateTimeTemplateDespatchITKeys = (row) => {
  let date = moment.utc().format(row.dateCreated);
  // Keep local time since timestamp is stored without timezone for DespatchITKeys.
  let utcDateTime = moment.utc(date).local(true).toDate();
  return <span>{moment(utcDateTime).format(dateTimeFormat)}</span>;
};
