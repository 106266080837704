import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant"; //warns us if we accidentally mutate state in the store
import thunk from "redux-thunk";
import { loadUser } from "redux-oidc";
import userManager from "../utils/userManager";

export default function configureStore(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //add support for Redux dev tools

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        reduxImmutableStateInvariant(),
        routerMiddleware(createBrowserHistory()) //Router middleware included here as is required by redux-oidc
      )
    )
  );

  loadUser(store, userManager); //loads potentially existing user data into the redux store (see https://github.com/maxmantz/redux-oidc/blob/master/docs/API.md)

  return store;
}
