import * as types from "./actionTypes";
import * as customerAdminApi from "../../api/customerAdminApi";
import { setError } from "./errorActions";
import { apiCallError, beginApiCall } from "./apiStatusActions";

export function getApplicationsListSuccess(applicationsList) {
  return { type: types.GET_APPLICATIONS_LIST_SUCCESS, applicationsList };
}

//This is a Thunk which is an action that fetch data from an api then dispatch an action with the fetched data
export function getApplicationsList() {
  return function (dispatch, getState) {
    //TODO: we can handle cache data by checking the store state in getState here
    dispatch(beginApiCall());
    return customerAdminApi
      .getApplications()
      .then((applicationsList) => {
        dispatch(getApplicationsListSuccess(applicationsList.items ?? []));
      })
      .catch((error) => {
        dispatch(
          setError("Could not get applications list from Customer Admin API")
        );
        dispatch(apiCallError());
        throw error;
      });
  };
}

export function addApplication(application) {
  return function (dispatch) {
    //TODO: Call Create Application API Endpoint on Facade
    dispatch(beginApiCall());
    return customerAdminApi
      .addApplication(application)
      .then((addApplicationResult) => {
        dispatch(addApplicationSuccess(addApplicationResult));
      })
      .catch((error) => {
        dispatch(
          setError("Could not create new application in Customer Admin API")
        );
        dispatch(apiCallError());
        throw error;
      });
  };
}

export function addApplicationSuccess(application) {
  return { type: types.ADD_APPLICATION_SUCCESS, application };
}

export function clearTemporaryCredentials() {
  return function (dispatch) {
    return Promise.resolve().then(() => {
      return dispatch({ type: types.CLEAR_TEMPORARY_CREDENTIALS });
    });
  };
}

export function getApplicationClaimsSuccess(claims) {
  return {
    type: types.GET_APPLICATIONS_CLAIMS_SUCCESS,
    applicationClaims: claims,
  };
}

export function saveApplicationClaimsSuccess(claims) {
  return {
    type: types.SAVE_APPLICATION_CLAIMS_SUCCESS,
    applicationClaims: claims,
  };
}

export function saveApplicationFieldsSuccess(application) {
  return {
    type: types.UPDATE_APPLICATION_FIELDS_SUCCESS,
    application,
  };
}

export function getApplicationCredentialsSuccess(credentials) {
  return {
    type: types.SAVE_APPLICATION_CREDENTIALS_SUCCESS,
    credentials: credentials,
  };
}

export function getApplicationClaims(appClientId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return customerAdminApi
      .getApplicationClaims(appClientId)
      .then((getApplicationClaimsResult) => {
        dispatch(getApplicationClaimsSuccess(getApplicationClaimsResult));
      })
      .catch((error) => {
        dispatch(
          setError("Could get accounts for application from Customer Admin API")
        );
        dispatch(apiCallError());
        throw error;
      });
  };
}

const updateClaims = (dispatch, appClientId, claims) => {
  return customerAdminApi
    .saveApplicationClaims(appClientId, { claims: claims })
    .then((claimsResult) => {
      dispatch(saveApplicationClaimsSuccess(claimsResult));
    });
};

const updateApplicationFields = (dispatch, appClientId, application) => {
  return customerAdminApi
    .saveApplication(appClientId, application)
    .then(() => {
      dispatch(saveApplicationFieldsSuccess({ ...application, appClientId }));
    });
};

export function updateApplication(appClientId, organisation, applicationName, isActive, claims) {
  return async function (dispatch) {
    dispatch(beginApiCall());

    return await Promise.allSettled([updateClaims(dispatch, appClientId, claims), updateApplicationFields(dispatch, appClientId, { organisation, applicationName, isActive })])
      .then(result=> {
        const statuses = result.map(promise => promise.status);
        const failedRequests = statuses.filter(s => s === 'rejected');

        if (failedRequests.length > 0) {
          dispatch(
            setError("Could not save application to Customer Admin API")
          );
          dispatch(apiCallError());
          
          if (failedRequests.length === 1) {
            throw new Error ('Save Application partially failed');
          }
          
          if (failedRequests.length === 2) {
            throw new Error ('Save Application failed');
          }
        }
      });
  };
}

export function getApplicationCredentials(appClientId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return customerAdminApi
      .getApplicationCredentials(appClientId)
      .then((credentials) => {
        dispatch(getApplicationCredentialsSuccess(credentials.appClientSecret));
      })
      .catch((error) => {
        dispatch(
          setError("Could not retrieve credentials from Customer Admin API")
        );
        dispatch(apiCallError());
        throw error;
      });
  };
}

