import { combineReducers } from "redux";
import applicationsReducer from "./applicationsReducer";
import errorReducer from "./errorReducer";
import { reducer as oidcReducer } from "redux-oidc";
import { routerReducer } from "react-router-redux";
import apiCallsInProgressReducer from "./apiStatusReducer";
import users from "./usersReducer";
import despatchITKeysReducer from "./despatchITKeysReducer";

const rootReducer = combineReducers({
  routing: routerReducer, //Router Reducer to handle storing routing history in state, requried for syncHistoryWithStore() react-oidc dependency
  oidc: oidcReducer, //Open ID Connect Reducer
  applications: applicationsReducer,
  error: errorReducer,
  apiCallsInProgress: apiCallsInProgressReducer,
  users: users,
  despatchitkeys: despatchITKeysReducer
});

export default rootReducer;
