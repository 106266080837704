import "./App.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginCallback from "./components/auth/loginCallback";
import LogoutCallback from "./components/auth/logoutCallback";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "./components/navigation/navigation.js";
import WithOidc from "./components/auth/withOidc";
import { useSelector } from "react-redux";
import ListApplications from "./components/applications/listApplications";
import AddApplication from "./components/applications/addApplication";
import EditApplication from "./components/applications/editApplication";
import UsersPage from "./components/users/UsersPage";
import ManageUserPage from "./components/users/ManageUserPage";// eslint-disable-line
import DisplayGlobalError from "./components/errors/displayGlobalError";
import Outage from "./components/outage/outage";
import DespatchITKeysPage from "./components/despatchITKeys/DespatchITKeysPage";
import ManageDespatchITKeysApplicationPage from "./components/despatchITKeys/ManageDespatchITKeysApplicationPage";// eslint-disable-line

function App() {
  const isMaintenance = process.env.REACT_APP_MAINTENANCE_WINDOW;

  if ([true, 'true'].includes(isMaintenance)) {
    return <Outage />;
  }

  const error = useSelector((state) => state.error.errorMessage);
  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar />
      <Switch>
        <Route path="/callback" component={LoginCallback} />
        <Route path="/logout" component={LogoutCallback} />
      </Switch>

      <Navigation />

      <WithOidc>
        {error && <DisplayGlobalError errorMessage={error} />}
        <Switch>
          <Route
            path="/applications/addApplication"
            component={AddApplication}
          />
          <Route
            path="/applications/editApplication/:appclientid"
            component={EditApplication}
          />

          <Route path="/despatchitkeys/addApplication" component={ManageDespatchITKeysApplicationPage} />
          <Route path="/despatchitkeys/:appId" component={ManageDespatchITKeysApplicationPage} />
          <Route path="/despatchitkeys" component={DespatchITKeysPage} />

          <Route path="/users/:userId" component={ManageUserPage} />
          <Route path="/users/addUser" component={ManageUserPage} />
          <Route path="/users" component={UsersPage} />
          <Route path="*" component={ListApplications} />
        </Switch>
      </WithOidc>
    </>
  );
}

export default App;