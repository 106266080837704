import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { createDateTimeTemplate } from "../../utils/datatableTemplate";
import FreDataTable from "../dataTable/FreDataTable";

function ListUsers({ users }) {
  users = users.map(user => ({ ...user, name: `${user.firstName} ${user.lastName}` }));
  return (
    <div className="mt-3 ml-5 mr-5">
      <Row>
        <Col>
          <h2>My Parcels Users</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 10, span: 2 }} xl={{ offset: 11, span: 1 }}>
          <Link data-test-id="add-user-button" to="/users/addUser" className="btn btn-primary">
            Add User
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <FreDataTable data={users}
            actionButtonBaseUrl="/users/"
            routeIdField="userId"
            columns={[
              {
                fieldName: "organisation",
                headerName: "Organisation"
              },
              {
                fieldName: "name",
                headerName: "Name"
              },
              {
                fieldName: "createdDateTime",
                headerName: "Created",
                body: createDateTimeTemplate
              },
              {
                fieldName: "brand",
                headerName: "Primary Brand"
              },
              {
                fieldName: "email",
                headerName: "Email"
              }
            ]}
            actionButtonViewToggleField="isActive"
          />
        </Col>
      </Row>
    </div>
  );
}

ListUsers.propTypes = {
  users: PropTypes.array.isRequired,
};

export default ListUsers;
