import React from "react";
import PropTypes from "prop-types";

const SelectInput = ({
  name,
  label,
  onChange,
  defaultOption,
  value,
  error,
  options,
  'data-test-id': dataTestId
}) => {
  let inputClass = "form-control";
  if (error && error.length > 0) {
    inputClass += " validation-error";
  }

  return (
    <div className="fieldBox">
      {label && <label htmlFor={name}>{label}</label>}
      <div className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select
          data-test-id={dataTestId || ''}
          name={name}
          value={value}
          onChange={onChange}
          className={inputClass}
        >
          <option value="">{defaultOption}</option>
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            );
          })}
        </select>
        {error && <span className="validation-error-message">{error}</span>}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  'data-test-id': PropTypes.string
};

export default SelectInput;
