import React from "react";
import { connect } from "react-redux";
import userManager from "../../utils/userManager";
import { Container, Row, Col, Button } from "react-bootstrap";
import DisplayError from "../errors/displayError";

class Login extends React.Component {
  onLoginButtonClick(event) {
    event.preventDefault();
    userManager.signinRedirect();
  }

  render() {
    return (
      <Container className="mt-2">
        <Row>
          <Col className="sm-4"></Col>
          <Col className="sm-4 pt-3 my-auto text-center">
            {this.props.errorMessage && (
              <DisplayError errorMessage={this.props.errorMessage} />
            )}
            <div className="content-well login-well">
              <h3>Customer Admin Login</h3>
              <p className="p-2">Please log in to continue</p>
              <Button
                onClick={this.onLoginButtonClick}
                variant="primary"
                className="mb-2"
              >
                Login via Okta
              </Button>
            </div>
          </Col>
          <Col className="sm-4"></Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.error.errorMessage,
  };
}

export default connect(mapStateToProps)(Login);
