import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import TextCopy from "../common/TextCopy";

const CredentialGenerator = ({ organisation, applicationName, secrets, onGenerate, onCloseOneTimeSecrets, showGenerateButton = true, showAlertMessage = true, showSecrets = false, onGenerateValidationHook = () => { return true; }, buttonLabel = "Regenerate Key", modalHeaderText = "Application API Credentials", shouldShowConfirmation = false }) => {
  const listSecrets = secrets.map((secret, index) => {
    return <div className="field grid" key={`secret${index}`}>
      <label className="col-12 md:col-3">{secret.name}</label>
      <div className="col-12 md:col-9">
        <TextCopy type={secret.name.match(/secret/i) ? 'password' : 'text'} value={secret.value} />
      </div>
    </div>;
  });

  const dialogFooter = (
    <Button data-test-id="close-credentials-button" onClick={onModalClose} className="p-button-secondary" label="Close" />
  );

  function acceptRegenerate() {
    if (onGenerateValidationHook()) {
      onGenerate();
    }
  }

  function onGenerateClick(event) {
    event.preventDefault();
    if (shouldShowConfirmation) {
      confirmPopup({
        target: event.currentTarget,
        message: (
          <div className="flex flex-row gap-5">
            <div className="flex flex-column align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle text-5xl text-warning"></i>
            </div>
            <div className="flex flex-column align-items-left justify-content-center gap-1">
              <span data-test-id="generator-confirm-text">Are you sure you want to regenerate the app key?</span>
              <span>The existing key for the application will no longer work. This action <strong>cannot</strong> be undone.</span>
            </div>
          </div>
        ),
        defaultFocus: "reject",
        acceptClassName: "p-button-warning",
        acceptLabel: "Yes, I understand",
        accept: acceptRegenerate
      });

      return;
    }

    acceptRegenerate();
  }

  function onModalClose() {
    if (showSecrets) {
      showSecrets = false;
      onCloseOneTimeSecrets();
    }
  }

  return <>
    <ConfirmPopup />
    <Dialog header={modalHeaderText} data-test-id='modal-credentials' visible={showSecrets} id="modal-credentials" onHide={onModalClose} footer={dialogFooter} closeOnEscape={true}>
      {(organisation || applicationName) && (<p>
        <label>
          {organisation} - {applicationName}
        </label>
      </p>)}
      { showAlertMessage && (
        <Alert variant="danger">
          <p>
            <strong>This secret cannot be viewed again</strong>
          </p>
          <p>
            Please securely copy these credentials. After you close this window
            the secret will not be visible again.
          </p>
        </Alert>
      )}
      <div>
        {listSecrets}
      </div>
    </Dialog>
    {showGenerateButton && (
      <Button data-test-id="generate-credential-button"
        type="button"
        className="p-button-warning"
        icon="pi pi-refresh"
        label={buttonLabel}
        onClick={onGenerateClick} />
    )}
  </>
}

CredentialGenerator.propTypes = {
  organisation: PropTypes.string,
  applicationName: PropTypes.string,
  secrets: PropTypes.array.isRequired,
  onGenerate: PropTypes.func,
  onCloseOneTimeSecrets: PropTypes.func,
  showGenerateButton: PropTypes.bool,
  showSecrets: PropTypes.bool,
  showAlertMessage: PropTypes.bool,
  onGenerateValidationHook: PropTypes.func,
  buttonLabel: PropTypes.string,
  modalHeaderText: PropTypes.string,
  shouldShowConfirmation: PropTypes.bool
};

export default CredentialGenerator;