import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";


const Breadcrumb = ({ links, name }) => {
  return (
    <div className="breadcrumbs">
      {links.map((link) => {
        return (
          <span key={link.path}>
            <Link to={link.path}>{link.name}</Link>
            <span className="breadcrumbs-separator">&raquo;</span>
          </span>
        );
      })}
      <span className="breadcrumbs-currentpage">{name}</span>
    </div>
  );
};

Breadcrumb.propTypes = {
    links: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired
};

Breadcrumb.defaultProps = {
    links: [],
    name: ""
};


export default Breadcrumb;
