import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as applicationsActions from "../../redux/actions/applicationsActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { createDateTimeTemplate } from "../../utils/datatableTemplate";
import Spinner from "../../components/common/spinner";
import FreDataTable from "../dataTable/FreDataTable";

export const ApplicationsDataTable = ({ apps, showInactiveApps = false }) => {
  return (
    <div className="mr-5 ml-5">
      <Row>
        <Col>
          <h2>Customer Integration Clients</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 10, span: 2 }} xl={{ offset: 11, span: 1 }}>
          <Link data-test-id="add-application-button" to="/applications/addApplication" className="btn btn-primary">
            Add Application
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <FreDataTable data={apps}
            actionButtonBaseUrl="/applications/editApplication/"
            routeIdField="appClientId"
            columns={[
              {
                fieldName: "organisation",
                headerName: "Organisation"
              },
              {
                fieldName: "applicationName",
                headerName: "Application Name"
              },
              {
                fieldName: "isActive",
                headerName: "Status",
                disableSearch: true
              },
              {
                fieldName: "createdDateTime",
                headerName: "Created",
                body: createDateTimeTemplate
              },
              {
                fieldName: "appClientId",
                headerName: "Client ID",
              }
            ]}
            inactiveToggle={{ canToggle: true, initialValue: showInactiveApps, fieldName: 'isActive', toggleText: 'Show Inactive Apps' }} />
        </Col>
      </Row>
    </div>
  );
}

class ListApplications extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.applicationsList.length == 0) {
      this.getApplications();
    }
  }

  getApplications() {
    this.props.actions.getApplicationsList();
  }

  addApplicationClick = (event) => {
    event.preventDefault();
    this.props.history.push("/applications/addApplication");
  };

  EditLink = (rowData) => {
    let url = "/applications/editApplication/" + rowData.appClientId;
    return (
      <Button
        onClick={(event) => {
          event.preventDefault();
          this.props.history.push(url);
        }}
      >
        Edit
      </Button>
    );
  };

  render() {
    return (
      <>
        {this.props.loading ? <Spinner /> :
          <div className="mt-3">
            <Row>
              <Col>
                <ApplicationsDataTable apps={this.props.applicationsList}> </ApplicationsDataTable>
              </Col>
            </Row>
          </div>}
      </>
    );
  }
}

ListApplications.propTypes = {
  applicationsList: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired, //ensures that dispatch is passed in (it gets passed in automatically when connect does not have mapDispatchToProps parameter)
  loading: PropTypes.bool.isRequired,
  showInactiveApps: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    applicationsList: state.applications.applicationsList,
    loading: state.apiCallsInProgress > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(applicationsActions, dispatch), //automatically maps all actions within dataActions.js instead of doing this for every action; getData: data => dispatch(actions.getData(data))
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListApplications)
);
