import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import TextInput from "../common/TextInput";
import SelectInput from "../common/SelectInput";
import Breadcrumb from "../navigation/breadcrumb";
import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import CredentialGenerator from "../credentialGenerator/CredentialGenerator";
import { Button } from 'primereact/button';
import TextCopy from "../common/TextCopy";
import moment from "moment";

const EditDespatchITKeysApplication = ({
  app,
  organisations,
  userPlans,
  oneTimeCredentials,
  onSave,
  onDelete,
  onCancel,
  onChange,
  onRegenerate,
  onGenerateValidationHook,
  onCloseOneTimeSecret,
  saving = false,
  showSecrets = false,
  errors = {},
}) => {
  const isEditMode = app.appId !== null && app.appId !== undefined && app.appId !== "";
  const getBreadcrumb = () => {
    return isEditMode ? "Edit Application" : "Add Application";
  };

  const getTitle = () => {
    return `${isEditMode ? "Edit" : "Add"} Application`;
  };

  const filterBy = (option, organisationInputProps) => {
    if (organisationInputProps.selected.length) {
      return true;
    }
    return option.toLowerCase().indexOf(organisationInputProps.text.toLowerCase()) > -1;
  };

  const handleOrganisationChangeTyped = (value) => {
    // Assign target.name and target.value expected by the handler
    const event = { target: { name: "organisation", value: value } };
    onChange(event);
  };

  const handleOrganisationChangeSelected = (selected) => {
    if (selected.length) {
      // Handle the "new selection" custom option from Typeahead
      const selectedValue = selected[0]["org-label"] ?? selected[0];
      
      // Assign target.name and target.value expected by the handler
      const event = { target: { name: "organisation", value: selectedValue } };
      onChange(event);
    }
  };

  const onActiveChanged = (inputSwitch) => {
    const event = { target: { name: "isActive", value: inputSwitch.value } };
    onChange(event);
  }

  const acceptDelete = () => {
    onDelete();
  }

  const showDeleteConfirmation = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this application?",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      acceptLabel: "Confirm deletion",
      accept: acceptDelete,
    })
  }
  function renderDateCreated() {
    if (!app.dateCreated) {
      return <span></span>;
    }
    return <span>{moment(moment.utc(app.dateCreated).local(true)).format("MMMM Do YYYY, h:mm:ss a")}</span>;
  }

  const dateCreatedSpan = renderDateCreated();

  return (
    <form onSubmit={onSave}>
      <ConfirmPopup />
      <Container className="mt-3">
        <Row>
          <Col>
            <Breadcrumb
              links={[{ path: "/despatchitkeys", name: "DespatchITKeys" }]}
              name={getBreadcrumb()}
              DespatchITKeys Applications
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{getTitle()}</h2>
            {errors.onSave && (
              <div className="alert alert-danger" role="alert">
                {errors.onSave}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="field">
              <label key="org-label">Organisation</label>
              <Typeahead
                filterBy={filterBy}
                labelKey="org-label"
                id="organisation-selection"
                options={organisations}
                placeholder="Enter or select organisation..."
                allowNew={true}
                selected={[app.organisation ?? '']}
                onChange={handleOrganisationChangeSelected}
                onInputChange={handleOrganisationChangeTyped}
              />
              {"organisation" in errors && (
                <span className="validation-error-message">
                  {errors.organisation}
                </span>
              )}
            </div>

            <TextInput
              id="name"
              label="Application Name"
              placeholder="Required. Enter application name"
              onChange={onChange}
              name="name"
              value={app.name ?? ''}
              error={errors.name}
            />

            <TextInput
              id="email"
              label="Email"
              placeholder="Required. Enter customer's email"
              name="email"
              onChange={onChange}
              value={app.email ?? ''}
              error={errors.email}
            />

            <SelectInput
              data-test-id="user-plan-select"
              id="userPlan"
              label="User Plan"
              onChange={onChange}
              name="userPlan"
              defaultOption="Select User Plan"
              value={app.userPlan ?? ''}
              error={errors.userPlan}
              options={userPlans.map((plan) => ({
                value: plan,
                text: plan,
              }))}
            />

          </Col>
        </Row>
        <hr />
        <Form.Group as={Row} className="mt-4">
          <Form.Label column sm="2">
            App ID
          </Form.Label>
          <Col sm="10">
            <TextCopy value={app.appId ?? ''} placeholder="Not set. App ID is generated once the application is created." />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-4">
          <Form.Label column sm="2">
            App Key
          </Form.Label>
          <Col sm="10">
            <CredentialGenerator applicationName={oneTimeCredentials.name} organisation={oneTimeCredentials.organisation}
              secrets={[
                { name: "App ID", value: oneTimeCredentials.appId },
                { name: "App Key", value: oneTimeCredentials.appKey },
                { name: "DespatchIT Key", value: oneTimeCredentials.despatchItKey }]}
              shouldShowConfirmation={isEditMode}
              showSecrets={showSecrets}
              onGenerate={onRegenerate}
              modalHeaderText="DespatchITKeys Application Credentials"
              onGenerateValidationHook={onGenerateValidationHook}
              buttonLabel={isEditMode ? "Regenerate API Key" : "Generate API Key"}
              onCloseOneTimeSecrets={onCloseOneTimeSecret} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-4">
          <Form.Label column sm="2">
            Is Active
          </Form.Label>
          <Col sm="10">
            <InputSwitch
              data-test-id="is-active-toggle"
              checked={app.isActive}
              onChange={onActiveChanged}
              disabled={!isEditMode}
            />
          </Col>
        </Form.Group>
        {app.dateCreated && <Form.Group as={Row} className="mt-4">
          <Form.Label column sm="2">
            <span>Date Created</span>
          </Form.Label>
          <Form.Label column sm="10">
            {dateCreatedSpan}
          </Form.Label>
        </Form.Group>}
        <hr />
        {isEditMode &&
          <Row>
            <Col>
              <Button
                data-test-id="delete-app"
                type="button"
                disabled={!isEditMode}
                value="Generate"
                icon="pi pi-times"
                className="p-button-danger"
                label="Delete Application"
                onClick={showDeleteConfirmation}
              ></Button>
            </Col>
          </Row>
        }
        <Row>
          <Col className="align-contents-right pt-4">
            <Button
              data-test-id="cancel-despatchitkeys-app"
              type="button"
              disabled={saving}
              value="Cancel"
              className="p-button-secondary"
              onClick={onCancel}
              label="Cancel" />
            {' '}
            <Button
              data-test-id="save-despatchitkeys-app"
              type="submit"
              disabled={saving}
              value="Save"
              icon="pi pi-save"
              className="p-button-primary"
              label={saving ? "Saving..." : "Save"} />
          </Col>
        </Row>
      </Container>
    </form>
  );
};

EditDespatchITKeysApplication.propTypes = {
  app: PropTypes.object.isRequired,
  organisations: PropTypes.array.isRequired,
  userPlans: PropTypes.array.isRequired,
  oneTimeCredentials: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRegenerate: PropTypes.func.isRequired,
  onGenerateValidationHook: PropTypes.func.isRequired,
  onCloseOneTimeSecret: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  showSecrets: PropTypes.bool,
  errors: PropTypes.object,
};

export default EditDespatchITKeysApplication;