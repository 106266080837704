import React, { useState, useEffect } from "react";
import UserForm from "./UserForm";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { loadUsers, saveUser, resendInvite } from "../../redux/actions/usersActions";

import PropTypes from "prop-types";
import getGuid from "../../utils/guid";
import { hasClaimData } from "../../utils/claims";

//Function declaration
export const ManageUserPage = ({ users, loadUsers, saveUser, resendInvite, history, ...props }) => {
  const [user, setUser] = useState({ ...props.user });
  const [saving, setSaving] = useState(false);
  const [resendingInvite, setResendingInvite] = useState(false);
  const [brands] = useState([
    "NZCouriers",
    "PostHaste",
    "CastleParcels",
    "NowCouriers",
  ]);
  const [errors, setErrors] = useState({});

  const newUserClaim = () => (
    {
      claimId: getGuid(),
      carrierName: null,
      roles: ["admin"],
      customerId: "",
    }
  );

  useEffect(() => {
    if (users.length === 0) {
      loadUsers().catch((error) => {
        alert("Loading users failed " + error);
      });
    } else {
      // add new empty row only if is edit user, not add user
      if (props.user.userId) {
        setUser({ ...props.user, claims: [...props.user.claims, newUserClaim()] });
      } else {
        setUser({ ...props.user });
      }
    }
  }, [props.user.userId]);

  //Events handlers
  function handleChange(event) {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  }

  function handleClaimChange(claimId, carrierName, data) {
    setUser((prevUser) => {
      const newUser = {
        ...prevUser,
        claims: prevUser.claims.map((claim) => claim.claimId === claimId ? { ...claim, ...data, carrierName } : claim),
        brand: user.userId ? user.brand : carrierName
      }

      const claimsWithData = [];
      const claimsWithNoData = [];

      // Will break the claims into two arrays depending on the fact that they have or not data
      newUser.claims.forEach(claim => {
        if (hasClaimData(claim)) {
          claimsWithData.push(claim);
        } else {
          claimsWithNoData.push(claim);
        }
      });

      // this guarantees that there is always at least one fully empty row (giving precedence to any empty row that may already exist)
      if (claimsWithNoData.length) {
        newUser.claims = [...claimsWithData, claimsWithNoData[0]];
      } else {
        newUser.claims = [...claimsWithData, newUserClaim()];
      }

      return newUser;
    });

    setErrors({});
  }

  function handleSave(event) {
    event.preventDefault();
    let organisation = user.organisation?.trim().length > 0 ? user.organisation : 'Not defined';
    let mobileNo = user.mobileNo?.trim().length > 0 ? user.mobileNo : '+64999999';

    if (!formIsValid(organisation, mobileNo)) return;
    setSaving(true);
    saveUser({
      ...user,
      organisation,
      mobileNo,
      claims: user.claims.filter((claim) => hasClaimData(claim)),
    })
      .then(() => {
        toast.success("User saved.");
        history.push("/users");
      })
      .catch((error) => {
        setSaving(false);
        (error.message === "The identifier is already in use")
          ? setErrors({ email: "The email is already in use" })
          : setErrors({ onSave: error.message });
      });
  }

  function handleResendInvite(event) {
    event.preventDefault();
    setResendingInvite(true);
    resendInvite(user)
      .then(() => {
        toast.success("Invite sent.");
        history.push("/users");
      })
      .catch((error) => {
        setResendingInvite(false);
        setErrors({ onResendInvite: error.message });
      });
  }

  //Form validation
  const isValidCustomerId = (customerId) => {
    const regex = "^[A-Z0-9]{1,8}$";
    return customerId.match(regex);
  };

  const isValidEmail = (email) => {
    const regex = "^[\\w\\-.]+@([\\w-]+.)+[\\w-]{2,20}$";
    return email.match(regex);
  };

  const isValidPhone = (mobileNo) => {
    const regex = "^[[ ]{0,}[+]{0,1}[0-9 ]{0,4}]*[(]{0,1}[0-9 ]{1,4}[)]{0,1}[-\\s\\.0-9]{6,15}$";
    return mobileNo.match(regex);
  };

  const isValidFirstName = (firstName) => {
    const regex = "^\\w{2,30}$";
    return firstName.match(regex);
  };

  const isValidLastName = (lastName) => {
    const regex = "^(?!\\s)(?=.{2,30}$)(?!.*\\s{2,})[A-Za-z0-9 ]{2,30}$";
    return lastName.match(regex);
  };

  const isValidOrganisation = (organisation) => {
    const regex = "^[A-Za-z0-9 _-]{2,80}$";
    return organisation.match(regex);
  };

  function formIsValid(organisation = user.organisation, mobileNo = user.mobileNo) {
    const _errors = {};

    if (!isValidOrganisation(organisation))
      _errors.organisation = "Organisation is not valid";
    if (!user.firstName) _errors.firstName = "First Name is required";
    if (user.firstName && !isValidFirstName(user.firstName))
      _errors.firstName = "First Name is not valid";
    if (!user.lastName) _errors.lastName = "Last Name is required";
    if (user.lastName && !isValidLastName(user.lastName))
      _errors.lastName = "Last Name is not valid";
    if (!user.email) _errors.email = "Email is required";
    if (!isValidEmail(user.email)) _errors.email = "Email is not valid";
    if (!isValidPhone(mobileNo)) _errors.mobileNo = "Phone is not valid";
    if (user.claims.length) {
      user.claims.filter((claim) => hasClaimData(claim) || user.claims.length === 1).forEach((claim) => {
        if (!claim.carrierName)
          _errors[`_claimsBrand_${claim.claimId}`] = "Brand is required";
        if (!claim.customerId)
          _errors[`_claimsCustomer_${claim.claimId}`] = "Customer is required";
        if ((claim.customerId || '') !== '' && !isValidCustomerId(claim.customerId))
          _errors[`_claimsCustomer_${claim.claimId}`] = "Customer is invalid";
      })
    }

    setErrors(_errors);

    //Form is valid when there is no errors
    return Object.keys(_errors).length === 0;
  }

  return <UserForm
    user={user}
    brands={brands}
    errors={errors}
    onChange={handleChange}
    onSave={handleSave}
    onClaimChange={handleClaimChange}
    saving={saving}
    onResendInvite={handleResendInvite}
    resendingInvite={resendingInvite}
  />;
}

//PropTypes declaration
ManageUserPage.propTypes = {
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  loadUsers: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  resendInvite: PropTypes.func.isRequired,
};

export function getUserByUserId(users, userId) {
  return users.find(user => user.userId === userId) || null;
}

//Redux mapping to determine which state and actions we need an access on the component
function mapStateToProps(state, ownProps) {
  const userId =
    ownProps.match.params.userId === "addUser"
      ? undefined
      : ownProps.match.params.userId;
  const newUser = {
    userId: null,
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    brand: "",
    organisation: "",
    claims: [
      {
        claimId: getGuid(),
        carrierName: null,
        roles: ["admin"],
        customerId: "",
      },
    ],
  };
  const user =
    userId && state.users.length > 0
      ? getUserByUserId(state.users, userId)
      : newUser;
  return {
    user,
    users: state.users
  };
}

const mapDispatchToProps = {
  loadUsers,
  saveUser,
  resendInvite
};

//Redux connect to link component to redux
export default connect(mapStateToProps, mapDispatchToProps)(ManageUserPage);
