import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Container, Nav, Navbar, Form } from "react-bootstrap";
import userManager from "../../utils/userManager";

export default function Navigation() {
  let path = useLocation();
  let history = useHistory();

  const logout = (event) => {
    event.preventDefault();
    userManager.removeUser().then(() => {
      history.push("/");
    });
  };

  return (
    // TODO Pick a different bg color for Navbar when DespatchITKeys is selected
    <Navbar bg={path.pathname.startsWith("/despatchitkeys") ? "secondary" : "primary"} variant="dark">
      <Container fluid="lg">
        <Navbar.Brand href="/">Customer Admin App</Navbar.Brand>
        <Nav className="mr-auto" activeKey={path.pathname}>
          <Nav.Link as={Link} to="/applications" className="nav-firstlink">
            Applications
          </Nav.Link>
          <Nav.Link data-test-id="users-tab" as={Link} to="/users" className="nav-link" >
            Users
          </Nav.Link>
          <Nav.Link data-test-id="despatchitkeys-tab" as={Link} to="/despatchitkeys" className="nav-link">
            DespatchITKeys
          </Nav.Link>
        </Nav>
        <Form>
          <Nav.Link
            variant="light"
            as={Link}
            to="/"
            onClick={logout}
            className="logout-link"
          >
            logout
          </Nav.Link>
        </Form>
      </Container>
    </Navbar>
  );
}
