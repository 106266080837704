import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Spinner from "../common/spinner.js";
import TextInput from "../common/TextInput";
import SelectInput from "../common/SelectInput";
import getGuid from "../../utils/guid";

const EditApplicationClaims = ({
  claims,
  brands,
  onClaimUpdated,
  onClaimRemoved,
  onClaimAdded,
  errors,
  loading,
}) => {
  const removeClaim = (claimId) => {
    onClaimRemoved(claimId);
  };

  const addClaim = () => {
    let claimId = getGuid();
    onClaimAdded({
      claimId: claimId,
      carrierName: "",
      customerId: "",
    });
  };

  const updateClaimBrand = (claimId, carrierName) => {
    let targetClaim = claims.find((claim) => claim.claimId == claimId);
    onClaimUpdated({
      ...targetClaim,
      carrierName: carrierName,
    });
  };

  const updateClaimCustomerId = (claimId, customerId) => {
    let targetClaim = claims.find((claim) => claim.claimId == claimId);
    onClaimUpdated({
      ...targetClaim,
      customerId: customerId,
    });
  };

  const getError = (errorList, index) => {
    if (errorList.length == 0) return "";

    let error = errorList.filter((x) => x.key == index);
    return (error.length > 0 && error[0].value) || "";
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th className="pl-0">Brand</th>
            <th>Customer Number</th>
            <th className="text-right pr-0">
              <Button
                className="btn-success"
                onClick={() => {
                  addClaim();
                }}
              >
                + Add
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {claims.length > 0 &&
            claims.map((claim) => {
              return (
                <tr key={claim.claimId}>
                  <td className="pl-0">
                    <SelectInput
                      name="carrierName"
                      value={claim.carrierName || ""}
                      defaultOption="Select Brand ..."
                      options={brands.map((brand) => ({
                        value: brand.carrierName,
                        text: brand.carrierName,
                      }))}
                      onChange={(event) =>
                        updateClaimBrand(claim.claimId, event.target.value)
                      }
                      error={
                        errors &&
                        getError(errors, `${claim.claimId}-carrierName`)
                      }
                    />
                  </td>
                  <td>
                    <TextInput
                      name="customerId"
                      value={claim.customerId || ""}
                      onChange={(event) =>
                        updateClaimCustomerId(claim.claimId, event.target.value)
                      }
                      error={
                        errors &&
                        getError(errors, `${claim.claimId}-customerId`)
                      }
                    />
                  </td>
                  <td className="text-right pr-0">
                    <Button
                      className="btn-danger"
                      onClick={() => removeClaim(claim.claimId)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {loading && <Spinner overlay={true} />}
    </>
  );
};

EditApplicationClaims.propTypes = {
  claims: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  onClaimUpdated: PropTypes.func.isRequired,
  onClaimRemoved: PropTypes.func.isRequired,
  onClaimAdded: PropTypes.func.isRequired,
  errors: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

export default EditApplicationClaims;
