import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import DisplayError from "./displayError";
import PropTypes from "prop-types";

function DisplayGlobalError({ errorMessage, retryAction }) {
  DisplayGlobalError.propTypes = {
    errorMessage: PropTypes.any,
    retryAction: PropTypes.any,
  };

  return errorMessage ? (
    <Container>
      <Row>
        <Col className="pt-3 pr-3 pl-3">
          <DisplayError errorMessage={errorMessage} retryAction={retryAction} />
        </Col>
      </Row>
    </Container>
  ) : null;
}

export default DisplayGlobalError;
