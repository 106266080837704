import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as despatchitkeysActions from "../../redux/actions/despatchITKeysActions";
import EditDespatchITKeysApplication from "./EditDespatchITKeysApplication";
import PropTypes from "prop-types";
import Spinner from "../common/spinner";

//Function declaration
export const ManageDespatchITKeysApplicationPage = ({ apps, loadApps, loadUserPlans, organisations, userPlans, saveApp, deleteApp, regenerateKey, clearOneTimeSecret, history, oneTimeCredentials, ...props }) => {
  const [app, setApp] = useState({ ...props.app });
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [showSecrets, setShowSecrets] = useState(false);

  useEffect(() => {
    if (apps.length === 0) {
      loadApps().catch((error) => {
        toast.error("Loading DespatchITKeys applications failed. " + error, { autoClose: false });
      });
    } else {
      setApp({ ...props.app });
    }
  }, [props.app.appId]);

  useEffect(() => {
    if (userPlans.length === 0) {
      loadUserPlans().catch((error) => {
        toast.error("Loading DespatchITKeys user plans failed. " + error, { autoClose: false });
      });
    }
  }, [props.userPlans]);

  //Events handlers
  function handleChange(event) {
    const { name, value } = event.target;
    setApp((prevApp) => ({
      ...prevApp,
      [name]: value,
    }));
  }

  function handleSave(event) {
    event.preventDefault();
    let organisation = app.organisation?.trim();

    if (!formIsValid(organisation)) return;
    setSaving(true);
    saveApp({
      ...app,
      organisation,
    })
      .then((savedApp) => {
        toast.success("Application saved.");
        // New app saved, go to edit route
        setSaving(false);
        if (!app.appId) {
          history.push(`/despatchitkeys/${savedApp.appId}`);
          return;
        }
        // Existing app saved, go to /despatchitkeys
        if (app.appId) {
          history.push("/despatchitkeys");
          return;
        }
      })
      .catch((error) => {
        setSaving(false);
        setErrors({ onSave: error.message });
      });
  }

  function handleCancel() {
    history.push("/despatchitkeys");
  }

  function handleDelete() {
    setSaving(true);
    deleteApp(app).then(() => {
      setSaving(false);
      toast.success("Application deleted");
      history.push('/despatchitkeys');
      return;
    }).catch((error) => {
      setSaving(false);
      toast.error(`Failed to delete application. Error: ${error.message}`, { autoClose: false })
    });
  }

  function handlePreGenerateValidationHook() {
    if (!app.appId) {
      // make sure form is in saveable state before we generate key for it
      if (!formIsValid()) {
        toast.error("Application must be saved before a key can be generated", { autoClose: false });
        return false;
      }
    }

    return true;
  }

  function generateKeyWrapper(appId) {
    regenerateKey(appId).then((despatchitkeysOts) => {
      setApp({ ...app, isActive: despatchitkeysOts.isActive });
      setSaving(false);
      setShowSecrets(true);
    }).catch((error) => {
      setSaving(false);
      setShowSecrets(false);
      toast.error(`Failed to generate app key. Error: ${error.message}`, { autoClose: false });
    });
  }

  function handleGenerateKey() {
    if (!app.appId) {
      setSaving(true);
      saveApp({ ...app }).then((savedApp) => {
        generateKeyWrapper(savedApp.appId);
      }).catch((e) => {
        toast.error(`Unable to generate key as the application failed to create. Error: ${e.message}`, { autoClose: false });
        return;
      });

      return;
    }

    generateKeyWrapper(app.appId);
  }

  function handleCloseOneTimeSecret() {
    let id = oneTimeCredentials.appId;
    clearOneTimeSecret().then(() => {
      setShowSecrets(false);
      history.push(`/despatchitkeys/${id}`);
    });
  }

  // Form validation
  const isValidEmail = (email) => {
    const regex = "^[\\w-.]+@([\\w-]+.)+[\\w-]{2,20}$";
    if (email) {
      return email.match(regex);
    }

    return false;
  };

  const isValidOrganisation = (organisation) => {
    const regex = "^.{2,80}$";
    if (organisation) {
      return organisation.match(regex);
    }

    return false;
  };

  function formIsValid(organisation = app.organisation) {
    const _errors = {};

    if (!isValidOrganisation(organisation))
      _errors.organisation = "Organisation is not valid";
    if (!isValidEmail(app.email)) _errors.email = "Email is not valid";
    if (!app.email) _errors.email = "Email is required";
    if (!app.userPlan) _errors.userPlan = "User Plan is required";
    if (!app.name) _errors.name = "Application Name is required";


    setErrors(_errors);

    //Form is valid when there is no errors
    return Object.keys(_errors).length === 0;
  }

  return <>{props.loading ? <Spinner /> : <EditDespatchITKeysApplication
    app={app}
    userPlans={userPlans}
    organisations={organisations}
    oneTimeCredentials={oneTimeCredentials}
    errors={errors}
    showSecrets={showSecrets}
    onChange={handleChange}
    onSave={handleSave}
    onDelete={handleDelete}
    onCancel={handleCancel}
    onRegenerate={handleGenerateKey}
    onGenerateValidationHook={handlePreGenerateValidationHook}
    onCloseOneTimeSecret={handleCloseOneTimeSecret}
    saving={saving}
  />}</>;
}

//PropTypes declaration
ManageDespatchITKeysApplicationPage.propTypes = {
  app: PropTypes.object.isRequired,
  apps: PropTypes.array.isRequired,
  organisations: PropTypes.array.isRequired,
  userPlans: PropTypes.array.isRequired,
  loadApps: PropTypes.func.isRequired,
  loadUserPlans: PropTypes.func.isRequired,
  saveApp: PropTypes.func.isRequired,
  deleteApp: PropTypes.func.isRequired,
  regenerateKey: PropTypes.func.isRequired,
  clearOneTimeSecret: PropTypes.func.isRequired,
  oneTimeCredentials: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

function getAppByAppId(apps, appId) {
  return apps.find(app => app.appId === appId) || null;
}

//Redux mapping to determine which state and actions we need an access on the component
function mapStateToProps(state, ownProps) {
  const appId =
    ownProps.match.params.appId === "addApplication"
      ? undefined
      : ownProps.match.params.appId;
  const newApp = {
    appId: null,
    name: "",
    email: "",
    organisation: "",
    userPlan: undefined,
  };
  const app =
    appId && state.despatchitkeys.applications.length > 0
      ? getAppByAppId(state.despatchitkeys.applications, appId)
      : newApp;

  return {
    app: app,
    apps: state.despatchitkeys.applications,
    oneTimeCredentials: state.despatchitkeys.temporaryCredentials,
    organisations: [
      ...new Set(
        state.despatchitkeys.applications.map(
          (item) => item.organisation || ""
        )
      ),
    ],
    userPlans: [
      ...new Set(
        state.despatchitkeys.userPlans.map(
          (item) => item.userPlanName || ""
        )
      ),
    ],
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadApps: despatchitkeysActions.getDespatchITKeysApplicationsList,
  saveApp: despatchitkeysActions.saveDespatchITKeysApplication,
  deleteApp: despatchitkeysActions.deleteDespatchITKeysApplication,
  loadUserPlans: despatchitkeysActions.getDespatchITKeysUserPlans,
  regenerateKey: despatchitkeysActions.regenerateDespatchITKeysApplicationKey,
  clearOneTimeSecret: despatchitkeysActions.clearDespatchITKeysOneTimeSecret
};

//Redux connect to link component to redux
export default connect(mapStateToProps, mapDispatchToProps)(ManageDespatchITKeysApplicationPage);
