import React, {useEffect} from "react";
import ListUsers from "./ListUsers";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/usersActions";
import PropTypes from "prop-types";
import Spinner from "../../components/common/spinner";

//Function declaration
function UsersPage({ users, loadUsers, ...props }) {
  
  useEffect(() => {
    if (users.length === 0) {
      loadUsers().catch((error) => {
        alert("Loading users failed" + error);
      });
    }
  }, []);
  return <>{props.loading ? <Spinner /> : <ListUsers users={users} />}</>;
}

//PropTypes declaration
UsersPage.propTypes = {
  users: PropTypes.array.isRequired,
  loadUsers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

//Redux mapping to determine which state and actions we need an access on the component
function mapStateToProps(state) {
  return {
    users: state.users,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadUsers: userActions.loadUsers,
};

//Redux connect to link component to redux
export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
