import React, {useEffect} from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as despatchitkeysActions from "../../redux/actions/despatchITKeysActions";
import PropTypes from "prop-types";
import Spinner from "../common/spinner";
import ListDespatchITKeysApplications from "./ListDespatchITKeysApplications";

//Function declaration
function DespatchITKeysPage({ despatchitkeysApps, loadDespatchITKeysApps, ...props }) {  
  useEffect(() => {
    if (despatchitkeysApps.length === 0) {
      loadDespatchITKeysApps().catch((error) => {
        toast.error("Loading DespatchITKeys applications failed. " + error, { autoClose: false });
      });
    }
  }, []);
  
  return <>{props.loading ? <Spinner /> : <ListDespatchITKeysApplications apps={despatchitkeysApps} />}</>;
}

//PropTypes declaration
DespatchITKeysPage.propTypes = {
  despatchitkeysApps: PropTypes.array.isRequired,
  loadDespatchITKeysApps: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

//Redux mapping to determine which state and actions we need an access on the component
function mapStateToProps(state) {
  return {
    despatchitkeysApps: state.despatchitkeys.applications,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadDespatchITKeysApps: despatchitkeysActions.getDespatchITKeysApplicationsList,
};

//Redux connect to link component to redux
export default connect(mapStateToProps, mapDispatchToProps)(DespatchITKeysPage);
