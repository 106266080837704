import * as actionTypes from "../actions/actionTypes";
import initialState from "../initialState";
import {sortDesc} from "../../utils/sort";

export default function usersReducer(state = initialState.users, action) {
  switch (action.type) {
    case actionTypes.LOAD_USERS_SUCCESS:
    {
      return sortDesc(action.users, 'createdDateTime');
    }
    case actionTypes.CREATE_USER_SUCCESS:
      return sortDesc([...state, { ...action.user }], 'createdDateTime');
    case actionTypes.UPDATE_USER_SUCCESS:
      return state.map((user) =>
        user.userId === action.user.userId ? action.user : user
      );
    case actionTypes.RESEND_INVITE_SUCCESS:
      return state.map((user) =>
          user.userId === action.user.userId ? action.user : user
      );
    default:
      return state;
  }
}