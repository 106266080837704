import React from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Login from "./login";

//This is a wrapper component that is used to wrap any routes or pages where the user must be logged in and have group access in order to view or edit
class WithOidc extends React.Component {
  constructor(props) {
    super(props);
  }

  loadingUser() {
    //redux-oidc will be in a loading user state while it tries to fetch an already existing user from the session state within the browser, which saves a round trip to the auth provider
    //a spinner is displayed on the page while this happens
    const { isLoadingUser } = this.props;
    return isLoadingUser;
  }

  validateUser() {
    //Only basic validation is done here to check if the user state is populated from oidc. the oidc-client handles ensuring the token is not tampered with,
    //and /components/auth/loginCallback.js validates that the user has group based access in order to use the app
    const { user } = this.props;
    if (!user || user.expired) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return this.loadingUser() ? (
      <Spinner />
    ) : this.validateUser() ? (
      this.props.children
    ) : (
      <Login />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser,
  };
}

export default withRouter(connect(mapStateToProps)(WithOidc));
