import { handleResponse, handleError } from "./apiUtils";
import store from "../redux/store";
const baseUrl = process.env.REACT_APP_API_URL + "/customer-admin/v1";
const applicationsPath = "/customer-integration/applications/";
const usersPath = "/customer-integration/users/";
const usersPathWithFilter = "/customer-integration/users?role=admin&status=all";
const despatchitkeysAppsPath = "/despatchitkeys/applications/";
const despatchitkeysUserPlansPath = "/despatchitkeys/user-plans/";

//Adds the Open ID Connect token to the API request
function fetchUsingToken(url, options) {
  let { method = "GET", headers = {}, body } = options || {};

  const token = store.getState().oidc.user?.id_token;

  headers = {
    ...headers,
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const optionsWithToken = {
    method,
    headers,
  };

  if (body) {
    optionsWithToken.body = body;
  }

  return fetch(url, optionsWithToken);
}

export function getApplications() {
  return fetchUsingToken(baseUrl + applicationsPath)
    .then(handleResponse)
    .catch(handleError);
}

export function getApplicationCredentials(appId) {
  return fetchUsingToken(`${baseUrl}${applicationsPath}${appId}/credentials`)
    .then(handleResponse)
    .catch(handleError);
}

export function getUsers() {
  return fetchUsingToken(baseUrl + usersPathWithFilter)
    .then(handleResponse)
    .catch(handleError);
}

export function getDespatchITKeysApplications() {
  return fetchUsingToken(baseUrl + despatchitkeysAppsPath)
    .then(handleResponse)
    .catch(handleError);
}

export function getDespatchITKeysUserPlans() {
  return fetchUsingToken(baseUrl + despatchitkeysUserPlansPath)
    .then(handleResponse)
    .catch(handleError);
}

export function saveDespatchITKeysApplication(app) {
  // new user
  if (!app.appId) {
    return fetchUsingToken(baseUrl + despatchitkeysAppsPath, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(app),
    })
      .then(handleResponse)
      .catch(handleError);
  }
  // update user
  else {
    return fetchUsingToken(baseUrl + despatchitkeysAppsPath + app.appId, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(app),
    })
      .then(handleResponse)
      .catch(handleError);
  }
}

export function deleteDespatchITKeysApplication(appId) {
  return fetchUsingToken(baseUrl + despatchitkeysAppsPath + appId, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function putDespatchITKeysApplicationKey(appId) {
  return fetchUsingToken(baseUrl + despatchitkeysAppsPath + appId + "/keys", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveUser(user) {
  user.brand = user.userId
    ? user.brand
    : user.claims[0].carrierName;

  user.createdDateTime = user.userId
    ? user.createdDateTime
    : new Date().toISOString();

  const claims = user.claims;

  if (!user.userId) { // new user
    return fetchUsingToken(baseUrl + usersPath, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...user, claims: [claims[0]] }),
    })
      .then((response) => {
        return claims.length === 1 ? handleResponse(response) : handleResponse(response)
          .then((responseFormatted) => {
            return saveUser({ ...responseFormatted, claims });
          })
          .catch(handleError);
      })
      .catch(handleError);
  } else { //update user
    return fetchUsingToken(baseUrl + usersPath + user.userId, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    })
      .then(handleResponse)
      .catch(handleError);
  }
}

export function resendInvite(user) {
  user.brand = user.userId
    ? user.brand
    : user.claims[0].carrierName;

  const userWithNoEmptyCarriers = { ...user };
  userWithNoEmptyCarriers.claims = [userWithNoEmptyCarriers.claims.filter((claims) => claims.carrierName && claims.customerId)[0]];

  return fetchUsingToken(baseUrl + usersPath + user.userId + "/resend-invite", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userWithNoEmptyCarriers),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addApplication(application) {
  return fetchUsingToken(baseUrl + applicationsPath, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(application),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveApplication(appClientId, application) {
  return fetchUsingToken(baseUrl + applicationsPath + appClientId, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(application),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApplicationClaims(appClientId) {
  return fetchUsingToken(
    baseUrl + applicationsPath + appClientId + "/claims/",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export function saveApplicationClaims(appClientId, claims) {
  return fetchUsingToken(
    baseUrl + applicationsPath + appClientId + "/claims/",
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(claims),
    }
  )
    .then(handleResponse)
    .catch(handleError);
}
