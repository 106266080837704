import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Button } from "react-bootstrap";
import TextInput from "../common/TextInput";
import SelectInput from "../common/SelectInput";
import Breadcrumb from "../navigation/breadcrumb";
import { hasClaimData } from "../../utils/claims";

const UserForm = ({
  user,
  brands,
  onSave,
  onChange,
  saving = false,
  errors = {},
  onClaimChange,
  onResendInvite,
  resendingInvite = false,
}) => {
  const getBreadcrumb = () => {
    if (user.isActive || !user.userId) {
      return user.userId ? "Edit" : "Add";
    }
    return 'View';    
  };

  const getTitle = () => {
    if (user.isActive || !user.userId) {
      return `${user.userId ? "Edit" : "Add"} User`;
    }
    return `Inactive user`;    
  };

  return (      
    <form onSubmit={onSave} className="userForm">
      <Container className="mt-3">
        <Row>
          <Col>
              <Breadcrumb
                  links={[{ path: "/users", name: "Users" }]}
                  name={getBreadcrumb()}
                  User
              />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{getTitle()}</h2>
            {errors.onSave && (
              <div className="alert alert-danger" role="alert">
                {errors.onSave}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              id="organisation"
              label="Organisation"
              placeholder="Up to 80 characters..."
              name="organisation"
              onChange={onChange}
              value={user.organisation}
              error={errors.organisation}
            />

            <TextInput
              id="firstName"
              label="First Name"
              placeholder="Required. Up to 30 characters..."
              onChange={onChange}
              name="firstName"
              value={user.firstName}
              error={errors.firstName}
            />

            <TextInput
              id="lastName"
              label="Last Name"
              placeholder="Required. Up to 30 characters..."
              onChange={onChange}
              name="lastName"
              value={user.lastName}
              error={errors.lastName}
            />

            <TextInput
              id="mobileNo"
              label="Phone"
              name="mobileNo"
              onChange={onChange}
              value={user.mobileNo}
              error={errors.mobileNo}
            />

            <TextInput
              id="email"
              label="Email"
              placeholder="Required..."
              name="email"
              onChange={onChange}
              value={user.email}
              error={errors.email}
              readOnly={!!user.userId}
            />
          </Col>
        </Row>        
        { !user.isEmailValidated && !!user.userId && user.isActive && (
          <Row className="pt-1">
            <Col>
              <Button
                  onClick={onResendInvite}
                  data-test-id="resend-invite-button"
                  variant="success"                  
                  value="Resend Invite Email"                  
              >
                {resendingInvite ? "Resending invite..." : "Resend Invite Email"}
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <table className="table table-striped table-hover mt-3" data-test-id="accounts-table">
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Customer Number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {user.claims.length > 0 &&
                  user.claims.map((claim, index) => {
                    return (
                      <tr key={claim.claimId} data-test-id={`account-row-${index}`}>
                        <td className="col-4">
                          <SelectInput
                            data-test-id={`carrier-selector-${index}`}
                            name="carrierName"
                            value={claim.carrierName || ""}
                            defaultOption="Select Brand ..."
                            options={brands.map((brand) => ({
                              value: brand,
                              text: brand,
                            }))}
                            onChange={(event) =>
                              onClaimChange(claim.claimId, event.target.value, {})
                            }
                            error={errors[`_claimsBrand_${claim.claimId}`]}
                          />
                        </td>
                        <td className="col-7">
                          <TextInput
                            data-test-id={`customer-id-${index}`}
                            name="customerId"
                            placeholder="Uppercase letters and Numbers only..."
                            value={claim.customerId || ""}
                            onChange={(event) =>
                              onClaimChange(claim.claimId, claim.carrierName, {customerId: event.target.value})
                            }
                            error={errors[`_claimsCustomer_${claim.claimId}`]}
                          />
                        </td>
                        <td className="">
                          { hasClaimData(claim) && index !== 0 && user.isActive && (
                            <Button
                              data-test-id={`delete-button-${index}`}
                              className="btn btn-danger"
                              onClick={() => onClaimChange(claim.claimId, null, {customerId: ""})}
                            >
                              Delete
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Col>
        </Row>
        {(!user.userId || user.isActive) && (
          <Row>
            <Col>
              <Button
                data-test-id="save-user"
                type="submit"
                disabled={saving}
                value="Save"
                className="btn btn-primary float-right"
              >
                {saving ? "Saving..." : "Save"}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </form>
  );
};

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClaimChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  saving: PropTypes.bool.isRequired,
  brands: PropTypes.array.isRequired,
  onResendInvite: PropTypes.func.isRequired,
  resendingInvite: PropTypes.bool.isRequired,
};

export default UserForm;
