import React from 'react';
import FISLogo from '../../assets/fis-logo.png';
import '../../css/outage.css';

const Outage = () => {
  return (
    <div className="outage-container">
      <div className="outage-content">
        <h1>Maintenance in Progress</h1>
        <p>
          We are currently performing scheduled maintenance on our website.
        </p>
        <p>
          During this time, the website will be temporarily unavailable. We apologise for any inconvenience this may cause.
        </p>
        <p>
          Maintenance is expected to be completed within the next few hours. Please check back soon.
        </p>
        <p>
          Thank you for your patience and understanding as we work to enhance your experience on our site.
        </p>
        <br/>
        <div className="outage-footer">
          <p>The FIS Team,</p>
          <p>Freightways Information Services Limited,</p>
          <p>DX Box CX10118, Freightways House, 32 Botha Road, Penrose, Auckland, New Zealand</p>
          <img src={FISLogo} alt="FIS" className="fis-logo" />
        </div>
      </div>
    </div>
  );
};

export default Outage;