import * as types from "./actionTypes";
import * as customerAdminApi from "../../api/customerAdminApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function getDespatchITKeysAppsSuccess(apps) {
  return { type: types.LOAD_SMAUG_APPS_SUCCESS, apps };
}

export function getDespatchITKeysApplicationsList() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return customerAdminApi
      .getDespatchITKeysApplications()
      .then((apps) => {
        dispatch(getDespatchITKeysAppsSuccess(apps.items ?? []));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function getDespatchITKeysUserPlansSuccess(plans) {
  return { type: types.LOAD_SMAUG_USER_PLANS_SUCCESS, plans };
}

export function getDespatchITKeysUserPlans() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return customerAdminApi
      .getDespatchITKeysUserPlans()
      .then((plans) => {
        dispatch(getDespatchITKeysUserPlansSuccess(plans.items ?? []));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function deleteDespatchITKeysApplicationSuccess(deletedApp) {
  return { type: types.DELETE_SMAUG_APPLICATION_SUCCESS, deletedApp };
}

export function deleteDespatchITKeysApplication(app) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return customerAdminApi
      .deleteDespatchITKeysApplication(app.appId)
      .then((deletedApp) => {
        dispatch(deleteDespatchITKeysApplicationSuccess(deletedApp))
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function updateDespatchITKeysApplicationSuccess(application) {
  return { type: types.UPDATE_SMAUG_APPLICATION_SUCCESS, application };
}

export function createDespatchITKeysApplicationSuccess(application) {
  return { type: types.CREATE_SMAUG_APPLICATION_SUCCESS, application };
}

export function saveDespatchITKeysApplication(app) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return customerAdminApi
      .saveDespatchITKeysApplication(app)
      .then((savedApp) => {
        app.appId
          ? dispatch(updateDespatchITKeysApplicationSuccess(savedApp))
          : dispatch(createDespatchITKeysApplicationSuccess(savedApp));
        return savedApp;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function regenerateDespatchITKeysApplicationKeySuccess(despatchitkeysOneTimeSecret) {
  return { type: types.REGENERATE_SMAUG_APPLICATION_KEY_SUCCESS, despatchitkeysOneTimeSecret };
}

export function regenerateDespatchITKeysApplicationKey(appId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return customerAdminApi
      .putDespatchITKeysApplicationKey(appId)
      .then((despatchitkeysOneTimeSecret) => {
        dispatch(regenerateDespatchITKeysApplicationKeySuccess(despatchitkeysOneTimeSecret))
        return despatchitkeysOneTimeSecret;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  }
}

export function clearDespatchITKeysOneTimeSecret() {
  return function (dispatch) {
    return Promise.resolve().then(() => {
      return dispatch({ type: types.CLEAR_SMAUG_ONE_TIME_SECRET });
    });
  };
}