import * as types from "../actions/actionTypes";

export default function errorReducer(state = {}, action) {
  switch (action.type) {
    case types.BEGIN_API_CALL:
      //this resets any errors that may be displaying if a new API call is initiated
      return {
        errorMessage: "",
      };
    case types.SET_ERROR:
      return {
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
}
