{
  /* Taken from https://github.com/maxmantz/redux-oidc-example/blob/master/src/components/callback.js */
}

import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../utils/userManager";
import jwt_decode from "jwt-decode";
import { bindActionCreators } from "redux";
import * as errorActions from "../../redux/actions/errorActions";

const requiredGroups = process.env.REACT_APP_OIDC_GROUPS;

//This component called when the user is redirected back to the app after logging in via the OIDC provider
class LoginCallback extends React.Component {
  render() {
    // just redirect to '/' in both cases
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={(response) => {
          //Validate if the token contains the valid group
          const decoded = jwt_decode(response.id_token);
          const groups = decoded.groups;

          var isValid = false;

          requiredGroups.split(",").forEach(function (item) {
            if (item.length === 0) return; //Ensure we are not validating against an empty string
            isValid = groups.some((g) => g.startsWith(item));
          });

          if (!isValid) {
            console.log(
              "user is invalid, this user is not configured for access to this app, expected groups prefixes:" +
                requiredGroups +
                ". Users groups: " +
                groups
            );
            userManager
              .removeUser()
              .then(() => {
                this.props.actions.setError(
                  "You are not configured for access to this app"
                );
              })
              .then(() => {
                this.props.history.push("/");
              });
          } else {
            this.props.history.push("/");
          }
        }}
        errorCallback={(error) => {
          this.props.history.push("/");
          console.error(error);
        }}
      >
        <div>logging in, Redirecting...</div>
      </CallbackComponent>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(errorActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(LoginCallback);
