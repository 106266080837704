import React from "react";
import "../../css/spinner.css";
import PropTypes from "prop-types";

const Spinner = ({ overlay }) => {
  return (
    <div className={overlay ? "loader-container overlay" : "loader-container"}>
      <div className="loader">Loading...</div>
    </div>
  );
};

//PropTypes declaration
Spinner.propTypes = {
    overlay: PropTypes.bool
};

export default Spinner;
