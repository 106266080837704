import React from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

//retryAction is intended to allow the user to retry the previous action by clicking a retry link. This is useful for a failed API call
function DisplayError({ errorMessage, retryAction }) {
  return errorMessage ? (
    <Alert variant="danger" className="p-2">
      {errorMessage} {retryAction && <Link onClick={retryAction}>Retry</Link>}
    </Alert>
  ) : null;
}

DisplayError.propTypes = {
  errorMessage: PropTypes.any,
  retryAction: PropTypes.any,
};

export default DisplayError;
