import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { InputSwitch } from 'primereact/inputswitch';

import { getApplicationsList } from "../../redux/actions/applicationsActions";

const EditApplicationDetails = ({
  getApplicationsList,
  organisations = [],
  organisation = "",
  applicationName = "",
  isActive = false,
  appClientId = "",
  onChange,
  errors = {},
}) => {
  useEffect(() => {
    if (organisations.length === 0) {
      getApplicationsList();
    }
  }, []);

  const filterBy = (option, state) => {
    return option.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  };

  const handleOrganisationChangeTyped = (value) => {
    onChange({ key: 'currentOrganisation', value});
  };

  const handleOrganisationChangeSelected = (selected) => {
    selected.length && onChange({ key: 'currentOrganisation', value: selected[0]});
  };

  const handleApplicationNameChange = (event) => {
    onChange({ key: 'currentApplication', value: event.target.value});
  };

  const handleActiveFlagChange = (event) => {
    onChange({ key: 'isActive', value: event.value});
  }

  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label column sm="4">
          Organisation Name:
        </Form.Label>
        <Col sm="8">
          <div className="field" data-test-id="organisation">
            <Typeahead
              filterBy={filterBy}
              id="organisations-edit"
              options={organisations}
              selected={[organisation]}
              onChange={handleOrganisationChangeSelected}
              onInputChange={handleOrganisationChangeTyped}
            />
            {errors.organisation && (
              <span data-test-id="organisationError" className="validation-error-message">
                {errors.organisation}
              </span>
            )}
          </div>
        </Col>
        <Form.Label column sm="4">
          Application Name:
        </Form.Label>
        <Col sm="8">
          <div className="field">
            <Form.Control
              data-test-id="application-name"
              type="text"
              value={applicationName}
              onChange={handleApplicationNameChange}
            />
            {errors.applicationName && (
              <span data-test-id="applicationNameError" className="validation-error-message">
                {errors.applicationName}
              </span>
            )}
          </div>
        </Col>
        <Form.Label column sm="4">
          Client ID:
        </Form.Label>
        <Col sm="8">
          <Form.Control plaintext readOnly value={appClientId} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-4">
        <Form.Label column sm="2">
          Is Active
        </Form.Label>
        <Col sm="10">
          <InputSwitch
            data-test-id="is-active-toggle"
            checked={isActive}
            onChange={handleActiveFlagChange}
          />
        </Col>
      </Form.Group>
    </Form>
  );
};

EditApplicationDetails.propTypes = {
  organisations: PropTypes.array,
  getApplicationsList: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  organisation: PropTypes.string,
  applicationName: PropTypes.string,
  isActive: PropTypes.bool,
  appClientId: PropTypes.string,
  errors: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    organisations: [
      ...new Set(
        state.applications.applicationsList.map(
          (item) => item.organisation || ""
        )
      ),
    ],
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  getApplicationsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditApplicationDetails);
