import * as types from "../actions/actionTypes";

export default function applicationsReducer(state = {}, action) {
  switch (action.type) {
    case types.GET_APPLICATIONS_LIST_SUCCESS:
      return {
        ...state,
        applicationsList: action.applicationsList,
      };
    case types.ADD_APPLICATION_SUCCESS:
      //TODO: the applicationsList data is complex so we should separate applicationClaims and temporaryCredentials out of applicationsList object and not have the 'items' object property
      return {
        ...state,
        applicationsList: [
          ...state.applicationsList,
          action.application
        ],
        temporaryCredentials: {
          organisation: action.application.organisation,
          applicationName: action.application.applicationName,
          appClientId: action.application.appClientId,
          appClientSecret: action.application.appClientSecret,
        },
      };
    case types.CLEAR_TEMPORARY_CREDENTIALS:
      return {
        ...state,
        temporaryCredentials: {
          organisation: "",
          applicationName: "",
          appClientId: "",
          appClientSecret: "",
        },
      };
    case types.GET_APPLICATIONS_CLAIMS_SUCCESS:
    case types.SAVE_APPLICATION_CLAIMS_SUCCESS:
      return {
        ...state,
        applicationClaims: action.applicationClaims,
      };

    case types.SAVE_APPLICATION_CREDENTIALS_SUCCESS:
      return {
        ...state,
        appCredentials: action.credentials,
      };

    case types.UPDATE_APPLICATION_FIELDS_SUCCESS:
      return {
        ...state,
        applicationsList: state.applicationsList.map((application) => {
          if (application.appClientId === action.application.appClientId) {
            return {
              ...application,
              ...action.application
            };
          } else {
            return application;
          }
        }),
      };
    default:
      return state;
  }
}
