import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { toast } from "react-toastify";

const TextCopy = ({ value, type="text", onCopySuccess = () => { toast.success("Text copied!"); }, placeholder = "", }) => {
  const [inputType, setInputType] = useState(type);

  function onCopy() {
    navigator.clipboard.writeText(value).then(() => {
      onCopySuccess();
    });
  }

  function toogleMask() {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }

  return (
    <div className="p-inputgroup flex-l">
      <InputText value={value} type={inputType} readOnly data-test-id="textCopyInput" placeholder={placeholder} />
      {type === 'password' && (
        <Button icon="pi pi-eye" type="button" data-test-id="toggleMaskButton" className="ml-1 p-inputgroup-addon p-button-secondary" onClick={toogleMask} disabled={!value} />
      )}
      <Button icon="pi pi-copy" type="button" data-test-id="copyButton" className="ml-1 p-inputgroup-addon p-button-secondary" onClick={onCopy} disabled={!value} />
    </div>
  )
}

TextCopy.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onCopySuccess: PropTypes.func
};

export default TextCopy;