import React from "react";
import { connect } from "react-redux";
import * as applicationsActions from "../../redux/actions/applicationsActions";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Breadcrumb from "../navigation/breadcrumb";
import Spinner from "../common/spinner.js";
import CredentialGenerator from "../credentialGenerator/CredentialGenerator";

class AddApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false, //gets set to ready when getApplicationsList call completed
      organisation: "",
      applicationName: "",
      errors: {},
    };
  }
  componentDidMount() {
    if (this.props.organisations.length == 0) {
      this.props.actions.getApplicationsList().then(() => {
        this.formReady();
      });
    } else {
      this.formReady();
    }
  }

  formReady() {
    this.setState({ ready: true });
  }

  cancelClick = (event) => {
    event.preventDefault();
    this.props.history.push("/");
  };

  addClick = (event) => {
    event.preventDefault();
    if (this.formIsValid()) {
      this.props.actions.addApplication(this.state).catch(() => {
        console.log("Add Application failed");
      });
    }
  };

  handleOrganisationChangeTyped = (value) => {
    this.setState({ ...this.state, organisation: value });
  };

  handleOrganisationChangeSelected = (selected) => {
    selected.length &&
      this.setState({ ...this.state, organisation: selected[0] });
  };

  handleApplicationNameChange = (event) => {
    this.setState({ ...this.state, applicationName: event.target.value });
  };

  onCloseCredentials = () => {
    let id = this.props.temporaryCredentials.appClientId;
    this.props.actions.clearTemporaryCredentials().then(() => {
      this.props.history.push(`/applications/editApplication/${id}`);
    });
  };

  setErrors(errors) {
    this.setState({ errors: errors });
  }

  formIsValid() {
    const errors = {};
    const regex = new RegExp("^[\\w\\s-]{1,100}$"); //the underlying API only supports this regex "[\w\s+=,.@-]+", we want less special chars in names so only words, whitespace and hyphens are allowed

    if (!this.state.organisation) {
      errors.organisation = "Organisation Name is required.";
    } else if (!regex.test(this.state.organisation)) {
      errors.organisation =
        "Organisation must be 100 characters or less, contain only numbers, letters, spaces and hyphens.";
    }

    if (!this.state.applicationName) {
      errors.applicationName = "Application Name is required.";
    } else if (!regex.test(this.state.applicationName)) {
      errors.applicationName =
        "Application Name must be 100 characters or less, contain only numbers, letters, spaces and hyphens.";
    }

    this.setErrors(errors);

    return Object.keys(errors).length === 0;
  }

  ToggleButton = ({ isOpen, onClick }) => (
    <button
      className="toggle-button"
      onClick={onClick}
      onMouseDown={(e) => {
        // Prevent input from losing focus.
        e.preventDefault();
      }}
    >
      {isOpen ? "▲" : "▼"}
    </button>
  );

  filterBy = (option, state) => {
    if (state.selected.length) {
      return true;
    }
    return option.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  };

  render() {
    return (
      <>
        <CredentialGenerator
          organisation={this.props.temporaryCredentials.organisation}
          applicationName={this.props.temporaryCredentials.applicationName}
          showGenerateButton={false}
          showSecrets={this.props.temporaryCredentials.appClientId.length > 0}
          secrets={[
            { name: "Client ID", value: this.props.temporaryCredentials.appClientId },
            { name: "Client Secret", value: this.props.temporaryCredentials.appClientSecret }
          ]}
          onCloseOneTimeSecrets={this.onCloseCredentials}
        />

        <Container className="mt-3">
          <Row>
            <Col>
              <Breadcrumb
                links={[{ path: "/", name: "Applications" }]}
                name="Add Application"
              />
              <h2>Add Application</h2>
              <div className="position-relative">
                {this.props.loading && <Spinner overlay={true} />}
              </div>
              <Form className="mt-3">
                <Form.Group as={Row} controlId="formOrganisationName">
                  <Form.Label column sm="4">
                    Organisation Name:
                  </Form.Label>
                  <Col sm="8">
                    <div className="field">
                      <Typeahead
                        filterBy={this.filterBy}
                        id="toggle-example"
                        options={this.props.organisations}
                        placeholder="Enter or select organisation ..."
                        onChange={this.handleOrganisationChangeSelected}
                        onInputChange={this.handleOrganisationChangeTyped}
                      />
                      {"organisation" in this.state.errors && (
                        <span className="validation-error-message">
                          {this.state.errors.organisation}
                        </span>
                      )}
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formApplicatonName">
                  <Form.Label column sm="4">
                    Application Name:
                  </Form.Label>
                  <Col sm="8">
                    <div className="field">
                      <Form.Control
                        type="text"
                        placeholder="Enter Application Name here..."
                        value={this.state.applicationName}
                        onChange={this.handleApplicationNameChange}
                      />
                      {"applicationName" in this.state.errors && (
                        <span className="validation-error-message">
                          {this.state.errors.applicationName}
                        </span>
                      )}
                    </div>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>

          <Row>
            <Col className="align-contents-right">
              <Button variant="secondary" onClick={this.cancelClick}>
                Cancel
              </Button>{" "}
              <Button
                variant="primary"
                onClick={this.addClick}
                disabled={this.state.ready ? false : true}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

AddApplication.propTypes = {
  organisations: PropTypes.array.isRequired,
  temporaryCredentials: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    organisations: [
      ...new Set(
        state.applications.applicationsList.map(
          (item) => item.organisation || ""
        )
      ),
    ],
    temporaryCredentials: state.applications.temporaryCredentials,
    loading: state.apiCallsInProgress > 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(applicationsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddApplication);
