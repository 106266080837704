{
  /* Taken from https://github.com/maxmantz/redux-oidc-example/blob/master/src/components/callback.js */
}

import React from "react";
import { connect } from "react-redux";
import { SignoutCallbackComponent } from "redux-oidc";
import userManager from "../../utils/userManager";

//NOTE: this logout callback is not used, but is available if we need to enable post_logout_redirect_uri within /utils/userManager,
//once enabled, /components/navigation/navigation.js should call userManager.signoutRedirect(); instead of userManager.removeUser(),
//this will destroy the users session and redux data and then send redirect to the auth provider endpoint to log the user
//out of their OIDC provider and then redirect back to /logout
class LogoutCallback extends React.Component {
  render() {
    // just redirect to '/' in both cases
    return (
      <SignoutCallbackComponent
        userManager={userManager}
        successCallback={(response) => {
          this.props.history.push("/");
        }}
        errorCallback={(error) => {
          this.props.history.push("/");
          console.error(error);
        }}
      >
        <div>logging out, Redirecting...</div>
      </SignoutCallbackComponent>
    );
  }
}

export default connect()(LogoutCallback);
