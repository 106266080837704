import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider as ReduxProider} from "react-redux";
import { OidcProvider } from "redux-oidc";
import { syncHistoryWithStore } from "react-router-redux";
import { createBrowserHistory } from "history";
import store from "./redux/store";
import userManager from "./utils/userManager";
import "react-bootstrap-typeahead/css/Typeahead.css";
import 'primeicons/primeicons.css';
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primeflex/primeflex.css';  

const history = syncHistoryWithStore(createBrowserHistory(), store);

ReactDOM.render(
  <ReduxProider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <Router history={history}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
    </OidcProvider>
  </ReduxProider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
