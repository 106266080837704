import * as types from "../actions/actionTypes";

export default function despatchitkeysReducer(state = {}, action) {
  switch (action.type) {
    case types.LOAD_SMAUG_APPS_SUCCESS:
      return {
        ...state,
        applications: action.apps
      };
    case types.LOAD_SMAUG_USER_PLANS_SUCCESS:
      return {
        ...state,
        userPlans: action.plans
      };
    case types.CREATE_SMAUG_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: [
          ...state.applications,
          action.application
        ],
        newAppId: action.application.appId
      };
    case types.UPDATE_SMAUG_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: state.applications.map((app) =>
          app.appId === action.application.appId ? action.application : app
        )
      }
    case types.DELETE_SMAUG_APPLICATION_SUCCESS:
      return {
        ...state,
        applications: state.applications.filter((app) => app.id !== action.deletedApp.id)
      };
    case types.REGENERATE_SMAUG_APPLICATION_KEY_SUCCESS:
      return {
        ...state,
        applications: state.applications.map((app) =>
          app.appId === action.despatchitkeysOneTimeSecret.appId ? { ...app, isActive: true } : app
        ),
        temporaryCredentials: {
          appId: action.despatchitkeysOneTimeSecret.appId,
          appKey: action.despatchitkeysOneTimeSecret.appKeys[0],
          despatchItKey: action.despatchitkeysOneTimeSecret.appKeys[1],
          name: action.despatchitkeysOneTimeSecret.name,
          organisation: action.despatchitkeysOneTimeSecret.organisation
        }
      };
    case types.CLEAR_SMAUG_ONE_TIME_SECRET:
      return {
        ...state,
        temporaryCredentials: {
          appId: "",
          appKey: "",
          despatchItKey: "",
          name: "",
          organisation: ""
        }
      };
    default:
      return state;
  }
}
