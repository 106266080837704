import { createUserManager } from "redux-oidc";

const userManagerConfig = {
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }/callback`,
  //post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/logout`,
  response_type: "code",
  scope: "openid profile groups",
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}/silent_renew.html`,
  automaticSilentRenew: false, //TODO: set to true for silent renew (find out how this works, see https://github.com/IdentityModel/oidc-client-js/wiki#configuration) - Barry C
  filterProtocolClaims: true,
  loadUserInfo: false, // User Info is not required at this point.
  revokeAccessTokenOnSignout: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
