export const SET_NAME = "SET_NAME";
export const LOAD_DATA_SUCCESS = "LOAD_DATA_SUCCESS";
export const GET_APPLICATIONS_LIST_SUCCESS = "GET_APPLICATIONS_LIST_SUCCESS";
export const GET_APPLICATIONS_CLAIMS_SUCCESS =
  "GET_APPLICATIONS_CLAIMS_SUCCESS";
export const SAVE_APPLICATION_CREDENTIALS_SUCCESS = "SAVE_APPLICATION_CREDENTIALS_SUCCESS";
export const ADD_APPLICATION_SUCCESS = "ADD_APPLICATION_SUCCESS";
export const CLEAR_TEMPORARY_CREDENTIALS = "CLEAR_TEMPORARY_CREDENTIALS";
export const EDIT_APPLICATION = "EDIT_APPLICATION";
export const SAVE_APPLICATION_CLAIMS_SUCCESS = "SAVE_APPLICATION_CLAIMS_SUCCESS";
export const UPDATE_APPLICATION_FIELDS_SUCCESS = "UPDATE_APPLICATION_FIELDS_SUCCESS";
export const SET_ERROR = "SET_ERROR";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS";
export const LOAD_SMAUG_APPS_SUCCESS = "LOAD_SMAUG_APPS_SUCCESS";
export const LOAD_SMAUG_USER_PLANS_SUCCESS = "LOAD_SMAUG_USER_PLANS_SUCCESS";
export const UPDATE_SMAUG_APPLICATION_SUCCESS = "UPDATE_SMAUG_APPLICATION_SUCCESS";
export const CREATE_SMAUG_APPLICATION_SUCCESS = "CREATE_SMAUG_APPLICATION_SUCCESS";
export const DELETE_SMAUG_APPLICATION_SUCCESS = "DELETE_SMAUG_APPLICATION_SUCCESS";
export const REGENERATE_SMAUG_APPLICATION_KEY_SUCCESS = "REGENERATE_SMAUG_APPLICATION_KEY_SUCCESS";
export const CLEAR_SMAUG_ONE_TIME_SECRET = "CLEAR_SMAUG_ONE_TIME_SECRET";
