export async function handleResponse(response) {
  
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  if (response.status === 404) {
    throw new Error("Resource not found");
  }
  if (response.status === 409) {
    throw new Error("The identifier is already in use");
  }
  if (response.status === 204) {
    throw new Error("No Data Found");
  }
  if (response.ok) return response.json();
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  throw error;
}
